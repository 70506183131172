import React, { useState } from 'react';
import { Wrapper, SizesBox, Size, Title, Note } from './SizesControl.style';

const SizesControl = ({ setSizeById, type, sizes }) => {
  const [note, setNote] = useState('Select Your Size');

  return (
    <Wrapper>
      <Title>Size Select</Title>
      <SizesBox>
        {sizes.map(({ icon, title, id }) => (
          <Size
            key={id}
            className={type === id ? 'active' : ''}
            onMouseEnter={() => setNote(title)}
            onMouseLeave={() => setNote('')}
            title={title}
            onClick={() => setSizeById(id)}
          >
            <img src={icon} sizes="(min-width: 768px) 500px, 75vw" alt={title} />
          </Size>
        ))}
      </SizesBox>
      <Note>{note}</Note>
    </Wrapper>
  );
};

export default SizesControl;
