import styled from 'styled-components';

export const Switch = styled.label`
  display: inline-block;
  font-size: 20px; /* 1 */
  /* height: 1em;
  width: 2em; */
  height: 0.7em;

  background: #959595;
  border-radius: 1em;
  transform: rotate(90deg) translate(-3%, 0%);
  cursor: pointer;

  div {
    height: 0.7em;
    width: 0.7em;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &.long {
    width: 3.5em;
    margin: 5px 0;

    input:checked + div {
      transform: translate3d(1.9em, 0, 0);
    }
  }

  &.short {
    width: 2.3em;

    input:checked + div {
      transform: translate3d(1em, 0, 0);
    }
  }
`;
