import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  opacity: 0.7;
`;

export const Title = styled.div`
  font-size: 0.875rem;
  line-height: 2rem;
  font-weight: 700;
`;
export const ControlsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 80px;
  ${({ moduleStyle }) => `
    width: ${moduleStyle.width};
    background-image: url(${moduleStyle.background});
  `}

  & > * {
    &:first-of-type {
      margin-left: -5px;
    }
    &:last-of-type {
      margin-right: -5px;
    }
  }
`;
export const Note = styled.div`
  height: 1.4rem;
  font-size: 0.75rem;
  line-height: 1.6rem;
  color: #666;
  text-align: center;
  width: 100%;
`;
