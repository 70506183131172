import React, { useState } from 'react';
import { modulesContolView, switches } from 'consts/configs';
import ToggleSwitch from 'components/ToggleSwitch';
import { Wrapper, ControlsBox, Title, Note } from './ModulesControl.style';

const ModulesControl = ({ setModules, modules, type }) => {
  const [note, setNote] = useState('Move Parts Around');

  const moduleStyle = modulesContolView[type];

  const handleToggle = (moduleId, values) => {
    const newModules = { ...modules, [moduleId]: values.show };
    setModules(newModules);
  };

  return (
    <Wrapper>
      <Title>Details Configuration</Title>
      <ControlsBox moduleStyle={moduleStyle}>
        {switches[type].map(({ isShort, note, on, off, id }) => (
          <ToggleSwitch
            key={id}
            isShort={isShort}
            onMouseEnter={() => setNote(note)}
            onMouseLeave={() => setNote('')}
            value={modules[id] === on}
            on={on}
            off={off}
            onChange={values => handleToggle(id, values)}
          />
        ))}
      </ControlsBox>
      <Note>{note}</Note>
    </Wrapper>
  );
};

export default ModulesControl;
