import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 600px;
  width: 100%;
  background: #fff;
  animation: fadein 1s;
  position: relative;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

    @media (min-width: 1024px) {
      position: absolute;
      left: -26rem;
      width: calc(100% + 45rem + 7.5rem);
      height: 120%;
      top: -20%;
    }
    @media (max-width: 768px) {
      position: absolute;
      left: 0;
      width: 100%;
      height: 80%;
      top: 10%;
    }
  }
  @media (max-width: 768px) {
    height: 450px;
  }

  .controlsWrapper,
  .sizesWrapper {
    position: absolute;
  }

  @media (max-width: 767px) {
    .controlsWrapper {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .sizesWrapper {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  /* @media (min-width: 768px) and (max-width: 1023px) { */
  @media (min-width: 768px) and (max-width: 1023px) {
    .controlsWrapper {
      top: 0;
      right: 0;
    }
    .sizesWrapper {
      top: 0;
      left: 0;
    }
  }

  @media (min-width: 1024px) {
    .controlsWrapper {
      bottom: 0;
      right: 0;
    }
    .sizesWrapper {
      bottom: 0;
      left: 0;
    }
  }
`;

export const Loader = styled.h1`
  width: 100%;
  line-height: 100%;
  text-align: center;
  animation: fadein 1s;
  background: #fff;
`;

export const LoaderBackground = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fadein 0.5s reverse;
  animation-fill-mode: none;
  background: #fff;
  opacity: 0;
  pointer-events: none;
`;

export const AnnotationControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: fadein 1s;
  height: 135px;
  font-weight: bold;
  pointer-events: none;

  @media (max-width: 767px) {
    height: 80%;
    top: 10%;
    justify-content: space-between;
    width: 100%;

    .home {
      position: absolute;
      bottom: 20%;
      right: 30px;
    }

    & > *:not(.home) {
      width: 20px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  & > * {
    pointer-events: all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    padding: 10px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
  }

  .home {
    background-image: url('./icons/play.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
  }
`;
