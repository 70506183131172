import React from 'react';
import { Switch } from './ToggleSwitch.style';

const ToggleSwitch = ({ value, onChange, isShort, on, off, ...rest }) => {
  return (
    <Switch className={isShort ? 'short' : 'long'} {...rest}>
      <input
        type="checkbox"
        defaultChecked={value}
        onChange={e =>
          onChange({
            show: e.target.checked ? on : off,
            hide: e.target.checked ? off : on
          })
        }
      />
      <div />
    </Switch>
  );
};

export default ToggleSwitch;
