import React, { useState } from 'react';
import Spinner from 'components/Spinner';
import ModulesControl from 'components/ModulesControl';
import SizesControl from 'components/SizesControl';
import { Wrapper, LoaderBackground, AnnotationControls } from './Model3dViewer.style';
import {
  useSketchfab,
  useUpdateCover,
  useUpdateCovers,
  useUpdateType,
  useUpdateModules,
  useChangeAnnotation
} from './Model3dViewer.hooks';
import { options, uid } from 'consts/sketchfab';
import { types, annotations, productProps } from 'consts/configs';

export default function Model3dViewer({
  type = types[0],
  cover,
  setCoversTextures,
  coversTextures,
  isFakeLoading,
  modules,
  setModules,
  handleTypeChange
}) {
  const iframeId = 'sketchfab';
  const [isLoading, api] = useSketchfab({ uid, iframeId, options });

  const [annotationIndex, setAnnotationIndex] = useState(0);

  useUpdateType(api, type, setModules);
  useUpdateModules(api, modules);
  useUpdateCover(api, cover, coversTextures);
  useUpdateCovers(api, setCoversTextures);
  useChangeAnnotation(api, annotationIndex);

  const updateAnnotation = (direction = 'home') => {
    const annotationsCount = annotations.length;

    switch (direction) {
      case 'backward':
        if (annotationIndex === 1) {
          setAnnotationIndex(annotationsCount - 1);
        } else {
          setAnnotationIndex(annotationIndex - 1);
        }
        break;
      case 'forward':
        if (annotationIndex + 1 === annotationsCount) {
          setAnnotationIndex(1);
        } else {
          setAnnotationIndex(annotationIndex + 1);
        }
        break;
      case 'home':
        setAnnotationIndex(1);
        break;
      default:
        setAnnotationIndex(1);
        break;
    }
  };

  const isReady = !(isLoading || isFakeLoading);

  return (
    <Wrapper isLoading={!isReady}>
      {!isReady && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Spinner />
        </div>
      )}
      {!!isReady && <LoaderBackground />}
      <iframe title="Ikea Model" id={iframeId} />
      {!!isReady && (
        <AnnotationControls>
          <div onClick={() => updateAnnotation('backward')}> ＜ </div>
          <div className="home" onClick={() => updateAnnotation('home')} />
          <div onClick={() => updateAnnotation('forward')}> ＞ </div>
        </AnnotationControls>
      )}
      {!!isReady && (
        <div className="controlsWrapper">
          <ModulesControl setModules={setModules} type={type} modules={modules} />
        </div>
      )}
      <div className="sizesWrapper">
        <SizesControl setSizeById={handleTypeChange} type={type} sizes={types.map(t => productProps[t])} />
      </div>
      {/* 
      <ul className="range-carousel-bullets js-carousel-bullets">
        {types
          .map(t => productProps[t])
          .map(({ icon, title, id }) => (
            <li
              className={
                'range-carousel-bullets__bullet js-range-carousel-bullets__bullet' +
                (type === id ? ' range-carousel-bullets__bullet--active' : '')
              }
            >
              <button className="bullet-clickable" type="button" title={title} onClick={() => handleTypeChange(id)}>
                <img
                  src="./DELAKTIG Sofa - Gunnared medium gray - IKEA_files/delaktig-3-seat-sofa__0668335_PE714363_S5.JPG"
                  src={icon}
                  className="carousel-bullets__bullet__image"
                  sizes="(min-width: 768px) 500px, 75vw"
                  alt={title}
                />
              </button>
            </li>
          ))}
      </ul> */}
    </Wrapper>
  );
}
