export const types = ['sofa1', 'sofa2', 'sofa3'];

export const productProps = {
  sofa1: { price: 500, title: 'Arm chair', icon: './icons/armchair.png', id: 'sofa1' },
  sofa2: { price: 660, title: 'Love seat', icon: './icons/loveseat.png', id: 'sofa2' },
  sofa3: { price: 770, title: 'Sofa', icon: './icons/sofa.png', id: 'sofa3' }
};

export const modulesContolView = {
  sofa1: {
    width: '108px',
    background: './images/armchair.svg'
  },
  sofa2: {
    width: '166px',
    background: './images/loveseat.svg'
  },
  sofa3: {
    width: '225px',
    background: './images/sofa.svg'
  }
};

export const covers = ['anthracite', 'beige', 'brown_pink', 'dark_blue', 'medium_gray', 'white_black'];
export const coversProps = {
  anthracite: {
    id: 'anthracite',
    name: 'Hillared anthracite',
    icon: './icons/anthracite.png',
    textureName: 'cloth_anthracite.jpg'
  },
  beige: { id: 'beige', name: 'Gunnared beige', icon: './icons/beige.png', textureName: 'cloth_beige.jpg' },
  brown_pink: {
    id: 'brown_pink',
    name: 'Gunnared light brown-pink',
    icon: './icons/brown_pink.png',
    textureName: 'cloth_brown_pink.jpg'
  },
  dark_blue: {
    id: 'dark_blue',
    name: 'Hillared dark blue',
    icon: './icons/dark_blue.png',
    textureName: 'cloth_dark_blue.jpg'
  },
  medium_gray: {
    id: 'medium_gray',
    name: 'Gunnared medium gray',
    icon: './icons/medium_gray.png',
    textureName: 'cloth_medium_gray.jpg'
  },
  white_black: {
    id: 'white_black',
    name: 'Tallmyra white/black',
    icon: './icons/white_black.png',
    textureName: 'cloth_white_black.jpgg'
  }
};

export const ModulesNodes = {
  sofa1: {
    leftSide: {
      a: '',
      b: ''
    },
    1: {
      a: '',
      b: ''
    },
    rightSide: {
      a: '',
      b: ''
    }
  },
  sofa2: {
    leftSide: {
      a: '',
      b: ''
    },
    1: {
      a: '',
      b: ''
    },
    2: {
      a: '',
      b: ''
    },
    rightSide: {
      a: '',
      b: ''
    }
  },
  sofa3: {
    l: {
      armrest: 'sofa3_armrest_l',
      side_table: 'sofa3_side_table_l'
    },
    backrest_1: {
      a: 'sofa3_backrest_1a',
      b: 'sofa3_backrest_1b'
    },
    backrest_2: {
      a: 'sofa3_backrest_2a',
      b: 'sofa3_backrest_2b'
    },
    backrest_3: {
      a: 'sofa3_backrest_3a',
      b: 'sofa3_backrest_3b'
    },
    r: {
      armrest: 'sofa3_armrest_r',
      side_table: 'sofa3_side_table_r'
    }
  }
};

export const defaultModules = {
  sofa1: {
    l: 'sofa1_armrest_l',
    backrest_1: 'sofa1_backrest_1a',
    r: 'sofa1_side_table_r'
  },
  sofa2: {
    l: 'sofa2_armrest_l',
    backrest_1: 'sofa2_backrest_1b',
    backrest_2: 'sofa2_backrest_2a',
    r: 'sofa2_side_table_r'
  },
  sofa3: {
    l: 'sofa3_armrest_l',
    backrest_1: 'sofa3_backrest_1b',
    backrest_2: 'sofa3_backrest_2a',
    backrest_3: 'sofa3_backrest_3a',
    r: 'sofa3_side_table_r'
  }
};

export const allModules = [
  'sofa1_armrest_l',
  'sofa2_armrest_l',
  'sofa3_armrest_l',
  'sofa1_armrest_r',
  'sofa2_armrest_r',
  'sofa3_armrest_r',

  'sofa1_side_table_l',
  'sofa2_side_table_l',
  'sofa3_side_table_l',
  'sofa1_side_table_r',
  'sofa2_side_table_r',
  'sofa3_side_table_r',

  'sofa1_backrest_1a',
  'sofa2_backrest_1a',
  'sofa3_backrest_1a',
  'sofa1_backrest_1b',
  'sofa2_backrest_1b',
  'sofa3_backrest_1b',

  'sofa2_backrest_2a',
  'sofa3_backrest_2a',
  'sofa2_backrest_2b',
  'sofa3_backrest_2b',

  'sofa3_backrest_3a',
  'sofa3_backrest_3b'
];

export const switches = {
  sofa1: [
    {
      id: 'l',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa1_side_table_l',
      off: 'sofa1_armrest_l'
    },
    {
      id: 'backrest_1',
      note: 'Backrest Rear / Front',
      on: 'sofa1_backrest_1b',
      off: 'sofa1_backrest_1a'
    },
    {
      id: 'r',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa1_side_table_r',
      off: 'sofa1_armrest_r'
    }
  ],
  sofa2: [
    {
      id: 'l',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa2_side_table_l',
      off: 'sofa2_armrest_l'
    },
    {
      id: 'backrest_1',
      note: 'Backrest Rear / Front',
      on: 'sofa2_backrest_1b',
      off: 'sofa2_backrest_1a'
    },
    {
      id: 'backrest_2',
      note: 'Backrest Rear / Front',
      on: 'sofa2_backrest_2b',
      off: 'sofa2_backrest_2a'
    },
    {
      id: 'r',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa2_side_table_r',
      off: 'sofa2_armrest_r'
    }
  ],
  sofa3: [
    {
      id: 'l',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa3_side_table_l',
      off: 'sofa3_armrest_l'
    },
    {
      id: 'backrest_1',
      note: 'Backrest Rear / Front',
      on: 'sofa3_backrest_1b',
      off: 'sofa3_backrest_1a'
    },
    {
      id: 'backrest_2',
      note: 'Backrest Rear / Front',
      on: 'sofa3_backrest_2b',
      off: 'sofa3_backrest_2a'
    },
    {
      id: 'backrest_3',
      note: 'Backrest Rear / Front',
      on: 'sofa3_backrest_3b',
      off: 'sofa3_backrest_3a'
    },
    {
      id: 'r',
      isShort: true,
      note: 'Armrest / Side Table',
      on: 'sofa3_side_table_r',
      off: 'sofa3_armrest_r'
    }
  ]
};

export const defaultSofaType = types[2];

export const defaultCover = covers[0];

export const annotations = [
  {
    index: 1,
    position: [27.163232381, -43.9796484348, 50.9582703935],
    eye: [351.8711750785, -567.1355504526, 204.1221402132],
    target: [0.0000457764, -0.0000599785, 39.1530025251]
  },
  {
    index: 2,
    position: [-2.9978714376, 9.1929107939, 44.4626040327],
    eye: [-1.7377336521, -319.0401821098, 368.8707389823],
    target: [0.0000457764, -0.0000599785, 39.1530025251]
  },
  {
    index: 3,
    position: [-104.5344487798, -10.8470275785, 44.7407376415],
    eye: [-309.8138011876, -136.9299294467, 36.84065955],
    target: [0.0000457764, -0.0000599785, 39.1530025251]
  },
  {
    index: 4,
    position: [8.6003589041, 2.7853558401, 25.8568313765],
    eye: [309.7569971354, -243.0297115486, -173.3713312228],
    target: [0.0000457764, -0.0000599785, 39.1530025251]
  }
];
