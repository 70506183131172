import React from 'react';
import SpinnerStyled from './Spinner.style';

const Spinner = ({ color }) => {
  return (
    <SpinnerStyled color={color}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </SpinnerStyled>
  );
};

export default Spinner;
