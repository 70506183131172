import { useEffect, useState, useRef } from 'react';
import SketchfabWrapper from 'sketchfab-view-api-wrapper';
import { types, annotations, allModules, defaultModules } from 'consts/configs';

export const useSketchfab = ({ uid, iframeId, options }) => {
  const [isLoading, setIsLoading] = useState(true);

  const sketchFabApiRef = useRef();

  useEffect(() => {
    const init = async () => {
      const loadWrapperPromise = new SketchfabWrapper({
        iframeQuery: `#${iframeId}`,
        uid,
        options,
        useSingleton: false
      });

      const wrapper = await loadWrapperPromise.init();

      const api = { ...wrapper, ...wrapper.api };

      sketchFabApiRef.current = api;
      window.api = api;

      setIsLoading(false);
    };
    init();
  }, [iframeId, options, uid]);

  return [isLoading, sketchFabApiRef.current];
};

export const useUpdateCovers = (api, setCoversTextures) => {
  useEffect(() => {
    if (api) {
      const covers = api.textures
        .filter(({ name }) => name && name.indexOf('cloth') !== -1)
        .map(({ name, uid }) => ({
          id: name.replace('cloth_', '').replace('.jpg', ''),
          uid
        }));
      setCoversTextures(covers);
    }
  }, [api, setCoversTextures]);
};

export const useUpdateType = (api, type, setModules) => {
  useEffect(() => {
    if (api && type && setModules) {
      types.forEach(api.hideNode);
      api.showNode(type);
      setModules(defaultModules[type]);
    }
  }, [api, type, setModules]);
};

export const useUpdateModules = (api, modules) => {
  useEffect(() => {
    if (api && modules) {
      allModules.forEach(api.hideNode);
      Object.keys(modules).forEach(key => {
        api.showNode(modules[key]);
      });
    }
  }, [api, modules]);
};

export const useUpdateCover = (api, cover, coversTextures) => {
  useEffect(() => {
    if (api && cover && coversTextures) {
      const uid = coversTextures.filter(t => t.id === cover)[0];
      const newCloth = api.materials.filter(m => m.name === 'cloth')[0];
      newCloth.channels.AlbedoPBR.texture = { uid };
      api.setMaterial(newCloth);
    }
  }, [api, cover, coversTextures]);
};

export const useChangeAnnotation = (api, annotationIndex) => {
  useEffect(() => {
    if (api && annotationIndex) {
      const annotation = annotations.filter(a => a.index === annotationIndex)[0];
      const { target, eye } = annotation;
      api.lookat(eye, target, 1);
    }
  }, [api, annotationIndex]);
};
