import styled from 'styled-components';

export const CoversWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  justify-content: space-between;
  align-items: space-between;
  margin: 0 -10px;
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;

export const CoverButton = styled.div`
  height: 70px;
  width: 70px;
  margin: 10px;
  box-sizing: content-box;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
  border: 2px solid #ccc;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 11%;
    height: 0;
    padding-bottom: 11%;
    margin: 0px;
  }

  @media (min-width: 769px) {
    min-width: 25%;
  }

  &.active {
    border-width: 2px;
    border-color: #666;
  }
`;
