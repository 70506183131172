import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  opacity: 0.7;
`;

export const Title = styled.div`
  font-size: 0.875rem;
  line-height: 2rem;
  font-weight: 700;
`;

export const SizesBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 80px;
`;

export const Size = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 60px;
  width: 60px;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f7f7f7;

  &.active {
    border-color: #000;
  }
`;

export const Note = styled.div`
  height: 1.4rem;
  font-size: 0.75rem;
  line-height: 1.6rem;
  color: #666;
  text-align: center;
  width: 100%;
`;
